@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Sora:wght@100..800&display=swap');

* {
  font-family: "Manrope", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Sora', sans-serif;
}

.text-custom-blue {
    color: #4467A1;
}

.border-custom-blue {
    border-color: #4467A1;
}

.bg-custom-blue {
    background-color: #4467A1;
}

.bg-custom-light-green {
    background-color: rgba(158, 193, 55, 0.3)
}

.bg-custom-green {
    background-color: #9EC137;
}

.relative-it {
    position: relative;
    top: -500px;
}