/* ContactUs.css */

/* Map Pin Design */
.map-pin {
    position: relative;
    width: 24px;
    height: 24px;
  }
  
  .pin-tail {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 20px solid #0070f3; /* Use your desired color here */
    border-top: 20px solid transparent;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .pin-dot {
    width: 12px;
    height: 12px;
    background-color: #0070f3; /* Use your desired color here */
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  